import request from '@/util/request'


// 合作伙伴列表 -- 所有组织公司
export function getpartners(params) {
  return request({
    url: `/api/system/Permission/Organize/selectAllList`,
    method: 'get',
    params
  })
}

// 合作伙伴列表 -- 所有组织公司
export function getOrganizeUnitList(params) {
  return request({
    url: `/api/system/Permission/Organize/getOrganizeUnitList`,
    method: 'get',
    params
  })
}

// 合作伙伴会员申请-- 动态模板(type:0:个， 1：团)
export function getTemple(params,teanant_id,type) {
  return request({
    url: `/api/member/memberBuildForm/getTemple/${teanant_id}/${type}`,
    method: 'get',
    params
  })
}

// 合作伙伴会员申请 -- 获取等级 (根据所选单位以及会员类型获得会员等级的下拉框数据) (type:0:个， 1：团)
export function getlevel(params,tenantId,type) {
  return request({
    url: `/api/member/memberApply/getlevel/${tenantId}/${type}`,
    method: 'get',
    params
  })
}
// 合作伙伴会员申请 -- 获取价钱，期限 (获得会员申请配置) (type:0:个， 1：团  ;level:等级)
export function getConfig(tenantId,type,level,params) {
  return request({
    url: `/api/member/memberApply/getConfig/${tenantId}/${type}/${level}`,
    method: 'get',
    params
  })
}

// 合作伙伴会员申请 -- 创建会员，成为会员 (保存所填的会员信息数据) (type:0:个， 1：团  ;level:等级)
export function createApply(data) {
  return request({
    url: "/api/member/memberApply/create",
    method: 'POST',
    data
  })
}

// 修改合作伙伴会员申请 -- 创建会员，成为会员 (保存所填的会员信息数据) (code,id)
export function  updateApply(data) {
  return request({
    url: "/api/member/memberApply/updateCode",
    method: 'POST',
    data
  })
}


// 合作伙伴会员申请-- 动态模板(id:)
export function getPartnersApply(params,id) {
  return request({
    url: `/api/member/memberBuildForm/${id}`,
    method: 'get',
    params
  })
}

// 获得当前用户申请的会员数据
export function getApplyData(id,params) {
  return request({
    url: `/api/member/memberApply/${id}`,
    method: 'get',
    params
  })
}


// 获得当前用户申请的会员数据
export function getMemberDataList(params) {
  return request({
    url: "/api/member/memberApply/getUsersMember",
    method: 'GET',
    params
  })
}

// 根据id获得会员配置
export function getConfigById(id,params) {
  return request({
    url: `/api/member/memberConfig/${id}`,
    method: 'GET',
    params
  })
}



// 根据id获得单位名称
export function getFullName(organizeId,params) {
  return request({
    url: `/api/system/Permission/Organize/getById/${organizeId}`,
    method: 'GET',
    params
  })
}


// 获取会员证
export function getMemberCard(params) {
  return request({
    url: '/api/member/memberCard/lookUpForMembercard',
    method: 'GET',
    params
  })
}

//会员证二维码转跳获取图片
export function getMemberCardByQrCode(params) {
  return request({
    url: '/api/member/memberCard/lookUpForMembercardByRaw',
    method: 'GET',
    params
  })
}

//获取体育资讯列表
export function getSportsConsulting(params) {
  return request({
    url: '/api/website/websiteList/getSportsConsulting',
    method: 'GET',
    params
  })
}


//网站-根据租户ID、会员类型获取会员配置(type:1:个人， 2：团体)
export function memberConfigGetList(teanantId,type) {
  return request({
    url: `/api/member/member/config/getList/${teanantId}/${type}`,
    method: 'get',
  })
}
//网站-获取单位会员申请模版(type:1:个人， 2：团体)
export function memberBuildFormGetTemplate(teanantId,type) {
  return request({
    url: `/api/member/member/build/form/getTemplate/${teanantId}/${type}`,
    method: 'get',
  })
}
//网站-申请会员
export function memberApplyConfirmApply(data) {
  return request({
    url: `/api/member/member/apply/confirmApply`,
    method: 'post',
    data
  })
}
//网站-修改会员申请
export function memberApplyConfirmApplyUpdate(data) {
  return request({
    url: `/api/member/member/apply/apply/update`,
    method: 'post',
    data
  })
}
//网站-会员申请列表
export function memberApplyGetUserMemberList(data) {
  return request({
    url: '/api/member/member/apply/getUserMemberList?currentPage='+data.currentPage+'&pageSize='+data.pageSize+'&memberType='+data.memberType,
    method: 'get',
    data
  })
}
//网站-会员详情
export function memberApplyInfo(id) {
  return request({
    url: '/api/member/member/apply/info/'+id,
    method: 'get',
  })
}

//查询用户是否为单位的会员
export function memberIsMember(tenantId,userId) {
  return request({
    url: `/api/member/member/apply/isOrgMember/${tenantId}/${userId}`,
    method: 'get',
  })
}
