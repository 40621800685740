<template>
  <div class="wrapper-content page-container">
    <div class="in_cooperation">
      <div class="in_cooperation-item" v-for="item in list" :key="item.id">
        <el-image class="logo-img" fit="contain" :src="loadUrl(item.logo)"></el-image>

        <div class="navs">
          <span @click="linkClick(item.id)">进入官网</span>
          <span @click="link(`/partners-apply/${item.id}`)">申请会员</span>
          <!-- <span>进入赛事</span> -->
        </div>
        <span class="sauto">{{ item.fullName }}</span>
        <div class="phoneNavs">
          <span @click="linkClick(item.id)">进入官网</span>
          <span @click="link(`/partners-apply/${item.id}`)">申请会员</span>
          <!-- <span>进入赛事</span> -->
        </div>
      </div>
      <el-empty
        class="center"
        v-if="list.length == 0"
        :image-size="200"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import { getOrganizeUnitList } from "@/api/partners";
import { getTenant } from "@/api/template";
export default {
  name: "partners-index",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.init();
    this.$store.dispatch("saveHeadActive", "/partners");
  },
  methods: {
    init() {
      const self = this;
      self.$store.commit("showLoading");
      getOrganizeUnitList().then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          self.$store.commit("hideLoading");
        } else {
          self.$toast(res.msg);
        }
      });
    },
    link(name) {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message({
          message: "请先登录",
          type: "warning",
          duration: 1500,
          onClose: () => {
            window.location.href = process.env.VUE_APP_SAAS_URL + "/login";
          },
        });
        return;
      }
      this.$router.push(name);
    },
    linkClick(id) {
      localStorage.setItem("domainTid", id);
      getTenant().then((res) => {
        if (res.data) {
          let data = res.data;
          let str = data.websiteLogo
            ? `http://${data.websiteLogo}${
                data.type == 1
                  ? "/#/template-first"
                  : data.type == 2
                  ? "/#/template-second"
                  : data.type == 3
                  ? "/#/template-third"
                  : data.type == 4
                  ? "/#/template-fourth"
                  : data.type == 5
                  ? "/#/template-fifth"
                  : data.type == 6
                  ? "/#/template-sixth"
                  : data.type == 7
                  ? "/#/template-seventh"
                  : data.type == 8
                  ? "/#/template-eighth"
                  : data.type == 9
                  ? "/#/template-ninth"
                  : data.type == 10
                  ? "/#/template-tenth"
                  : ""
              }`
            : "";
          window.open(str);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-empty__description {
  text-align: center;
}
</style>
